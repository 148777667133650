import { gql } from 'graphql-request'
import client from './index'
import scryptPwd from '../utils/scryptPwd'

export const signup = async (data = {}) => {
  const res = await client.request(
    gql`
      mutation($IndiPasswd: String, $IndiMobile: String) {
        IndiSocialMemberRegistered(
          IndiPasswd: $IndiPasswd
          IndiMobile: $IndiMobile
          IndiRegisteredName: ""
          IndiEmail: ""
          IndiLoginMethod: ""
          IndiLoginMethodId: ""
          Model: "10101"
          IndiSocialId: "1100000000000000000"
          IndiQq: ""
        )
      }
    `,
    {
      IndiPasswd: await scryptPwd(data.password),
      IndiMobile: data.phone,
    }
  )

  return res
}
