<template>
  <div class="auth">
    <div class="content">
      <div class="left"></div>
      <div class="right">
        <div class="panel" v-show="panelType === 'login'">
          <div class="title panel-row">登录</div>

          <div class="panel-row" @keyup.enter="signin">
            <el-form label-width="0px" label-position="left">
              <el-form-item>
                <el-input
                  v-model="formData.phone"
                  prefix-icon="el-icon-user-solid"
                  placeholder="手机号"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  type="password"
                  prefix-icon="el-icon-lock"
                  placeholder="请输入密码"
                  v-model="formData.pwd"
                  maxlength="16"
                />
                <div class="found-link">
                  <el-link @click="panelType = 'found'">找回密码</el-link>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="signin"
                  :loading="signinPending"
                  >立即登录</el-button
                >
              </el-form-item>
            </el-form>
          </div>

          <div class="panel-row">
            <el-link @click="panelType = 'signup'">注册</el-link>
          </div>
        </div>

        <div class="panel" v-show="panelType === 'signup'">
          <div class="title panel-row">注册</div>
          <div class="panel-row">
            <el-form label-width="0px" label-position="left">
              <el-form-item>
                <el-input
                  v-model="formData.phone"
                  prefix-icon="el-icon-user-solid"
                  placeholder="手机号"
                />
              </el-form-item>
              <el-form-item>
                <div style="display: flex; align-items: center">
                  <el-input
                    style="margin-right: 8px"
                    type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入验证码"
                    v-model="formData.smsCode"
                    maxlength="16"
                  />
                  <el-button @click="sendCode" :disabled="!!sendCodeCount">{{
                    sendCodeCount ? `${sendCodeCount}秒后获取` : "获取验证码"
                  }}</el-button>
                </div>
                <div style="line-height: 1.5; font-size: 12px; color: #666">
                  备注：请确保本人操作，验证码将通过短信发送到您的手机。手机号码将作为平台交易及修改密码等各项操作。
                </div>
              </el-form-item>

              <el-form-item>
                <el-input
                  type="password"
                  prefix-icon="el-icon-lock"
                  placeholder="设置登录密码"
                  v-model="formData.pwd"
                  maxlength="16"
                />
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="signup"
                  :loading="signupPending"
                  >立即注册</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div @click="panelType = 'login'">
            <el-link>登录</el-link>
          </div>
        </div>

        <div class="panel" v-show="panelType === 'found'">
          <div class="title panel-row">找回密码</div>
          <div class="panel-row">
            <el-form label-width="0px" label-position="left">
              <el-form-item>
                <el-input
                  v-model="formData.phone"
                  prefix-icon="el-icon-user-solid"
                  placeholder="手机号"
                  maxlength="11"
                />
              </el-form-item>
              <el-form-item>
                <div style="display: flex; align-items: center">
                  <el-input
                    style="margin-right: 8px"
                    type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入验证码"
                    v-model="formData.smsCode"
                    maxlength="16"
                  />
                  <el-button @click="sendCode" :disabled="!!sendCodeCount">{{
                    sendCodeCount ? `${sendCodeCount}秒后获取` : "获取验证码"
                  }}</el-button>
                </div>
              </el-form-item>

              <el-form-item>
                <el-input
                  type="password"
                  prefix-icon="el-icon-lock"
                  placeholder="新密码"
                  v-model="formData.pwd"
                  maxlength="16"
                />
              </el-form-item>

              <el-form-item>
                <el-input
                  type="password"
                  prefix-icon="el-icon-lock"
                  placeholder="请确认密码"
                  v-model="formData.pwd"
                  maxlength="16"
                />
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="signup"
                  :loading="signupPending"
                  >确认修改</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div @click="panelType = 'login'">
            <el-link>登录</el-link>
          </div>
        </div>
      </div>
    </div>

    <!-- logo 和返回首页 -->
    <div class="logo">
      <div class="img">
        <img :src="require('@/assets/images/in-peak.png')" alt="" srcset="" />
      </div>
      <div class="text">
        <div class="title">应必客</div>
        <div class="url">in-peak.com</div>
      </div>
    </div>
    <div class="bottom-btn" @click="$router.push('/ori-home')">
      <i class="el-icon-s-home"></i>
    </div>
  </div>
</template>

<script>
import { signin, sendSmsCode, checkSmsCode } from "@/http/query.js";
import { signup } from "@/http/mutate.js";
export default {
  data() {
    return {
      // login signup
      panelType: "login",

      formData: {
        phone: "",
        pwd: "",

        smsCode: "",
        smsSign: "",
      },

      signinPending: false,
      signupPending: false,

      sendCodeCount: 0,
      sendCodePending: false,
    };
  },

  methods: {
    async signin() {
      const { phone, pwd } = this.formData;

      if (this.signinPending) return;
      this.signinPending = true;

      await signin({
        phone: phone,
        password: pwd,
      });

      this.$message.success("登录成功！");

      this.$router.push("/ori-home");

      this.signinPending = false;
    },

    async signup() {
      const { phone, pwd } = this.formData;

      if (this.signupPending) return;
      this.signupPending = true;

      await signup({
        phone: phone,
        password: pwd,
      });

      this.$message.success("注册成功！");

      // this.$router.push("/ori-home");
      this.panelType = "login";

      this.signupPending = false;
    },

    // 发送验证码
    async sendCode() {
      const { sendCodeCount } = this;
      const { phone } = this.formData;

      if (
        this.sendCodePending ||
        sendCodeCount ||
        isNaN(phone) ||
        phone.length !== 11
      )
        return;
      this.sendCodePending = true;

      const res = await sendSmsCode({
        IndiSocialId: "",
        IndiOwnerId: "",
        IndiMobile: phone,
      });

      this.sendCodePending = false;

      if (res) {
        this.formData.smsSign = res;
        this.sendCodeCount = 60;
        this.$message.success("发送成功。");
        const timer = setInterval(() => {
          this.sendCodeCount--;

          if (!this.sendCodeCount) {
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$message.error("发送失败。");
      }
    },

    // 验证验证码
    async checkCode() {
      const { phone, smsCode, smsSign } = this.formData;

      if (this.signupPending) return;
      this.signupPending = true;

      const res = await checkSmsCode({
        SmsSign: smsSign,
        SmsCode: smsCode,
        IndiMobile: phone,
      });

      this.signupPending = false;

      if (!res) {
        this.$message.error("验证码错误。");
      } else {
        this.signup();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  position: relative;
  .content {
    display: flex;
    // width: 1200px;
    // margin: 30px auto;

    .left {
      padding: 30px;
      width: 40%;
      height: 100vh;
      background: #f5f9fc;
      background-image: url("../../../assets/images/left-auto.png");
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .right {
      width: 60%;
      height: 100vh;
      display: flex;
      align-items: center;
    }

    .panel {
      width: 300px;
      margin: 0 auto;
      .title {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        color: #666;
      }

      .panel-row + .panel-row {
        margin-top: 20px;
      }

      .found-link {
        text-align: right;
        line-height: initial;

        ::v-deep .el-link {
          font-size: 14px;
          color: #666;
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: 30px;
    left: 30px;
    color: #333;
    display: flex;
    align-items: center;

    .img {
      width: 60px;
      img {
        width: 100%;
      }
    }

    .text {
      margin-left: 10px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .bottom-btn {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    left: 30px;
    bottom: 30px;
    border: 1px solid #eeeff1;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
.auth {
  .content {
    @media screen and(max-width: 920px) {
      .left {
        display: none;
      }

      .right {
        width: 100%;
      }
    }
  }
}
</style>
